import React from 'react'
import { Helmet } from 'react-helmet-async'

export const AttentiveTag = () => {
  return (
    <Helmet>
      <script src="https://cdn.attn.tv/prepsportswear/dtag.js" async></script>
    </Helmet>
  )
}
