import algolia from './algolia'
import cart from './cart'
import channels from './channel'
import contentful from './contentful'
import dispatchErrors from './dispatchErrors'
import error from './error'
import globals from './globals'
import headers from './headers'
import media from './media'
import { combineReducers } from 'redux'
import { helpers, reducers } from '@teamrazr/redux'
import boldMetrics from '@/redux/reducers/boldMetrics'

export default {
  account: reducers.identity,
  algolia: reducers.identity,
  bot: reducers.identity,
  cart,
  cart_details: helpers.createApiReducer('cart_details'),
  channels: combineReducers({
    channels,
    subchannels: helpers.createApiReducer('subchannels'),
    subchannelsTree: helpers.createApiReducer('subchannelsTree')
  }),
  contentful_entries: contentful,
  cookie: reducers.identity,
  dataDog: reducers.identity,
  designs: helpers.createApiReducer('designs'),
  dispatch_errors: dispatchErrors,
  env: reducers.identity,
  error,
  flags: reducers.identity,
  globals,
  headers,
  hosts: reducers.identity,
  media,
  newImageServer: reducers.identity,
  pathname: reducers.identity,
  product_categories: helpers.createApiReducer('product_categories'),
  search: algolia,
  storageObjects: reducers.identity,
  stores: helpers.createApiReducer('stores'),
  stripe: reducers.identity,
  stripe_pkey: reducers.identity,
  tokens: reducers.identity,
  tracking: reducers.identity,
  userAgent: reducers.identity,
  yotpo: reducers.identity,
  yotpo_products: helpers.createApiReducer('yotpo_products'),
  boldMetrics
}
