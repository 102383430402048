import PropTypes from 'prop-types'
import React from 'react'
import Router from 'react-router/lib/Router'
import { Provider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import { getHelmetData } from '@/components/hooks/getHelmetData'

const Root = ({ store, ...props }) => {
  const helmetData = getHelmetData()

  return (
    <Provider store={store}>
      <HelmetProvider helmetData={helmetData}>
        <Router {...props} />
      </HelmetProvider>
    </Provider>
  )
}

Root.propTypes = {
  store: PropTypes.object.isRequired
}

export default Root
