import { actions } from '@teamrazr/redux'

const { types } = actions

export default (state = { loaded: false }, action) => {
  switch (action.type) {
    case types.SET_BOLDMETRICS_LOADED: {
      return { loaded: true }
    }

    default:
      return state
  }
}
