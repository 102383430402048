import { HelmetData } from 'react-helmet-async'

let helmetData: HelmetData | null = null
const helmetDataInit = {}

// Probably not a real hook, but I think it's a good place to store the helmet data
export const getHelmetData = (): HelmetData => {
  if (!helmetData) {
    helmetData = new HelmetData(helmetDataInit)
  }
  return helmetData
}
